import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Button from '../components/button/button'
import SEO from "../components/seo"

import image from '../images/contact.jpg'

import { motion } from "framer-motion"

const ConnectPage = () => { 
  const transition = {
    type: "spring",
    mass: 0.35,
    stiffness: 75,
    duration: 0.3
  };

  return (
  <Layout>
    <SEO title="About" />
    <section id={`cover`} className={`primary header-fix lg`} style={{
        backgroundImage: `url(${image})`,
        backgroundSize: `cover`,
        backgroundPosition: `center center`,
        backgroundRepeat: `no-repeat`,
        boxSizing: `border-box`
      }}>
      <div className={`content`}>
        <motion.h3 className={`center thin`} initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 20 }}
          transition={transition}>Work with us</motion.h3>
        <motion.h1 className={`center`} initial={{ opacity: 0, y: -40 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 40 }}
          transition={transition}>We're glad you're here</motion.h1>
      </div>
    </section>
    <section className={``}>
      <div className={`content--sm`}>
        <h2 className={`margins-bottom center`}>Tell us about yourself</h2>
        <form className={`contact-form`}>
          <h6>Name <sup>*</sup></h6>
          <input type={`text`} placeholder={`Your Full Name`} required/>
          <h6>Company or Organisation <sup>*</sup></h6>
          <input type={`text`} placeholder={`Your Company or Organisation`} required/>
          <h6>E-mail <sup>*</sup></h6>
          <input type={`email`} placeholder={`Your E-mail`} required/>
          <h6>Contact <sup>*</sup></h6>
          <input type={`tel`} placeholder={`Your Contact Number`} required/>
          <h6>Purpose <sup>*</sup></h6>
          <select>
            <option>Start a project</option>
            <option>Schedule a meeting</option>
            <option>Enquire</option>
          </select>
          <h6>Message</h6>
          <textarea placeholder={`Tell us about your project, timeline, enquiry`} data-gramm_editor="false"></textarea>
          <button className={`submit`}>Send</button>
        </form>
      </div>
    </section>
    <section className={`dark`}>
      <div className={`content--sm`}>
        <h4 className={`center text`}>or simply reach out at</h4>
        <h1 className={`center data-hover`}><a href="mailto:hey@creavo.co">hey@creavo.co</a></h1>
      </div>
    </section>
  </Layout>
)
}

export default ConnectPage
